<script setup>
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import ButtonLink from '@/Components/Button/Link.vue';
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import StaffLayout from '@/Layouts/StaffLayout.vue';

const { t } = useI18n();
const route = inject('route');
const props = defineProps({
    query: Object,
    faqs: Object,
    categoryOptions: Object,
    paginationLengthOptions: Object,
});

const typeFilters = {
    category: {
        options: { '': t('All {attributes}', { attributes: t('categories') }), ...props.categoryOptions },
    },
};

const form = useForm({
    search: props.query.search ?? '',
    orderBy: props.query.orderBy ?? 'due_date',
    orderDir: props.query.orderDir ?? 'desc',
    status: props.query.status ?? '',
    per_page: props.query.per_page ?? 25,
    page: props.query.page ?? 1,
    category_id: props.query.category_id ?? '',
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('staff.faqs.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['faqs', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    { label: t('Order'), width: '5%', orderBy: 'order_column' },
    { label: t('Category'), width: '20%' },
    { label: t('Question'), width: '33%' },
    { label: t('Answer'), width: '50%' },
    { label: t('Employer'), width: '5%' },
    { label: t('Fl@xr'), width: '5%' },
    { label: '', cell: 'Arrow', width: '2%' },
];

const tableData = computed(() =>
    props.faqs.data.map((faq) => {
        return {
            id: faq.id,
            colorCode: faq.deleted_at == null ? '#1BB21B' : '#C30000',
            rowData: [
                faq.order_column,
                faq.category_name,
                faq.question,
                faq.answer,
                faq.employer ? t('Yes') : t('No'),
                faq.flexer ? t('Yes') : t('No'),
                route('staff.faqs.edit', faq.id),
            ],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <StaffLayout :title="$t('FAQs')">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            :h1="$t('FAQs')"
            :data="faqs"
            :typeFilters="typeFilters"
            :categoryOptions="categoryOptions"
        >
            <ButtonLink :href="route('staff.faqs.create')">
                {{ $t('Add {model}', { model: $t('FAQ') }) }}
            </ButtonLink>
        </Filters>
        <template v-if="typeof faqs !== 'undefined' && faqs.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof faqs !== 'undefined'"
                :links="faqs.links"
                @setPage="setPage"
                :pageData="faqs"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('FAQs') }) }}
            </div>
        </template>
    </StaffLayout>
</template>
